<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col :cols="12">
        <p
            class="
                        text-h5

                        text-uppercase
                        d-flex
                        align-center
                        lighten__font
                    "
        >类型管理
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2 float-end"
              small
              color="info"
              @click="add"
          >
            添加类型
          </v-btn>
        </p>
      </v-col>
      <v-col :cols="12">
        <v-data-table :headers="headers" :items="category">
          <template v-slot:item.action="{item}">
            <v-btn small  color="primary"  class="mr-1" @click="edit(item)">修改</v-btn>
            <v-btn small  color="error"  class="mr-1" @click="del(item.id)">删除</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>{{ id ? '修改类型' : '添加类型' }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field outlined dense v-model="item.name" label="名称">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field outlined dense type="number" v-model="item.type" label="类型">

              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="update">{{ id ? '确认修改' : '确认添加'}}</v-btn>
          <v-btn small text @click="dialog=false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deldialog" width="800">
      <v-card>
        <v-card-title>
          确认删除该类型？
        </v-card-title>
        <v-card-text>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="deleteType">确认</v-btn>
          <v-btn small text @click="deldialog=false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {delType, getType, editType, createType} from "../../api/type";

export default {
  name: "index",
  data() {
    return {
      tab: null,
      category: [],
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: '名称',
          value: 'name'
        },
        {
          text: '类型',
          value: 'type'
        },
        {
          text: '操作',
          value: 'action'
        }
      ],
      item: {},
      id: '',
      dialog:false,
      deldialog:false
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    getTypes() {
      getType().then(res => {
        const {results} = res
        this.category = results
      })
    },
    add(){
      this.item={}
      this.id=''
      this.dialog=true
    },
    edit(item) {
      this.id=item.id
      this.item=JSON.parse(JSON.stringify(item))
      this.dialog=true
    },
    del(id) {
      this.id = id
      this.deldialog=true
    },
    async update(){
      console.log(this.item)
      if (this.id){
        try {
          await editType(this.item)
          this.$snackbar({
            content: '修改类型成功',
            color: 'success',
            showClose: true,
          })
        }catch (e) {
          console.log(e)
        }finally {
          this.dialog=false
          this.getTypes()
        }

      }else{
        try {
          await createType(this.item)
          this.$snackbar({
            content: '添加类型成功',
            color: 'success',
            showClose: true,
          })
        }catch (e) {
          console.log(e)
        }finally {
          this.dialog=false
          this.getTypes()
        }
      }
    },
    async deleteType(){
      try {
        await delType(this.id)
        this.$snackbar({
          content: '删除类型成功',
          color: 'success',
          showClose: true,
        })
      }catch (e){
        console.log(e)
      }finally {
        this.deldialog=false
        this.getTypes()
      }

    }
  }
}
</script>

<style scoped>

</style>